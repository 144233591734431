import PropTypes from 'prop-types'
import React from 'react'
import { CountdownWeekendsTitle, CountdownWeekendsWrapper } from './index.style'

export const getPassedWeekends = weekends => {
  const now = new Date()

  return weekends.filter(weekend => {
    const weekendDate = new Date(weekend.date)

    return weekendDate < now
  })
}

const CountdownWeekends = ({ weekends, theme = 'small' }) => {
  const passedWeekends = getPassedWeekends(weekends)

  const closestPassedWeekend = passedWeekends[passedWeekends.length - 1]

  return (
    <CountdownWeekendsWrapper theme={theme}>
      <CountdownWeekendsTitle theme={theme}>
        {closestPassedWeekend.title}
      </CountdownWeekendsTitle>
    </CountdownWeekendsWrapper>
  )
}

CountdownWeekends.propTypes = {
  weekends: PropTypes.array.isRequired,
  theme: PropTypes.oneOf(['small', 'large']),
}

export default CountdownWeekends
